
import { defineComponent } from 'vue'
import 'bulma-quickview/dist/css/bulma-quickview.min.css'

export default defineComponent({
  name: 'SidePane',
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],

  data (): { width: string | null } {
    return {
      width: null,
    }
  },

  methods: {
    onMouseDown (): void {
      const containerWidth = window.innerWidth

      const onMouseMove = ({ pageX }: MouseEvent) => {
        this.width = `${containerWidth - pageX}px`
      }

      const onMouseUp = () => {
        window.removeEventListener('mousemove', onMouseMove)
        window.removeEventListener('mouseup', onMouseUp)
      }

      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
    },
  },
})
