
import { defineComponent } from 'vue'
import HydraOperationButton from '@/components/HydraOperationButton.vue'
import BMessage from '@/components/BMessage.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import PageContent from '@/components/PageContent.vue'
import SharedDimensionTags from '@/components/SharedDimensionTags.vue'
import ExternalTermLink from '@/components/ExternalTermLink.vue'
import TermDisplay from '@/components/TermDisplay.vue'
import DownloadButton from '@/components/DownloadButton.vue'
import { SharedDimension, SharedDimensionTerm } from '../store/types'
import { confirmDialog } from '@/use-dialog'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'SharedDimensionView',
  components: {
    BMessage,
    HydraOperationButton,
    LoadingBlock,
    PageContent,
    SharedDimensionTags,
    ExternalTermLink,
    TermDisplay,
    DownloadButton
  },

  data () {
    return {
      pageSizes: [10, 20, 50, 100],
      tableWidth: 3,
    }
  },

  mounted (): void {
    const id = this.$route.params.id
    this.$store.dispatch('sharedDimension/fetchDimension', id)
  },

  beforeUnmount (): void {
    this.$store.dispatch('sharedDimension/reset')
  },

  computed: {
    ...mapState('sharedDimension', {
      dimension: 'dimension',
      terms: 'terms',
      page: 'page',
      pageSize: 'pageSize',
    }),
  },

  methods: {
    nextPage (): void {
      this.$store.dispatch('sharedDimension/nextPage')
    },

    prevPage (): void {
      this.$store.dispatch('sharedDimension/prevPage')
    },

    changePageSize (newPageSize: number): void {
      this.$store.dispatch('sharedDimension/changePageSize', newPageSize)
    },

    deleteDimension (dimension: SharedDimension): void {
      confirmDialog({
        title: dimension.actions.delete?.title,
        message: 'Are you sure you want to delete this shared dimension?',
        confirmText: 'Delete',
        onConfirm: async () => {
          await this.$store.dispatch('api/invokeDeleteOperation', {
            operation: dimension.actions.delete,
            successMessage: `Dimension ${dimension.name} deleted successfully`,
          })
          this.$router.push({ name: 'SharedDimensions' })
        },
      })
    },

    deleteTerm (term: SharedDimensionTerm): void {
      confirmDialog({
        title: term.actions.delete?.title,
        message: 'Are you sure you want to delete this term?',
        confirmText: 'Delete',
        onConfirm: () => {
          this.$store.dispatch('api/invokeDeleteOperation', {
            operation: term.actions.delete,
            successMessage: 'Term deleted successfully',
            callbackAction: 'sharedDimension/removeTerm',
            callbackParams: term,
          })
        },
      })
    },
  },
})
