import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.value), 1))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode("Missing value")
        ])
      ]))
}