
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import BMessage from '../components/BMessage.vue'

export default defineComponent({
  name: 'LogoutView',
  components: { BMessage },

  mounted (): void {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'Home' })
    }
  },

  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'oidcIsAuthenticated',
    })
  },
})
