
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'MaterializeJobs',

  computed: {
    ...mapGetters('project', {
      materializeLabel: 'materializeLabel',
    }),
  },
})
