import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b35fff1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "is-flex is-justify-content-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_message = _resolveComponent("b-message")!
  const _component_radio_button = _resolveComponent("radio-button")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_rdf_editor = _resolveComponent("rdf-editor")!
  const _component_loading_block = _resolveComponent("loading-block")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: "Preview table mapping (CSVW)",
    onClose: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_b_message, { type: "is-danger" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.csvw.error), 1)
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.csvw.error]
      ]),
      (_ctx.csvw.data)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_o_field, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formats, (format) => {
                    return (_openBlock(), _createBlock(_component_radio_button, {
                      key: format.value,
                      "native-value": format.value,
                      modelValue: _ctx.selectedFormat,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFormat) = $event)),
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(format.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["native-value", "modelValue"]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_o_button, {
                size: "small",
                "icon-left": "clipboard",
                onClick: _ctx.copy
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Copy ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createVNode(_component_rdf_editor, {
              value: _ctx.csvw.data,
              format: _ctx.selectedFormat,
              prefixes: _ctx.editorPrefixes,
              ref: "snippet",
              readonly: ""
            }, null, 8, ["value", "format", "prefixes"])
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_loading_block, null, null, 512), [
        [_vShow, _ctx.csvw.isLoading]
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}