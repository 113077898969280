"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const icons = {
    [rdf_ns_builders_1.qudt.NominalScale.value]: 'th-large',
    [rdf_ns_builders_1.qudt.OrdinalScale.value]: 'list-ul',
    [rdf_ns_builders_1.qudt.IntervalScale.value]: 'chart-line',
    [rdf_ns_builders_1.qudt.RatioScale.value]: 'balance-scale-right',
};
exports.default = (0, vue_1.defineComponent)({
    name: 'ScaleOfMeasureIcon',
    props: {
        scaleOfMeasure: {
            type: Object,
            default: undefined,
        },
    },
    computed: {
        label() {
            var _a, _b;
            return (_b = (_a = this.scaleOfMeasure) === null || _a === void 0 ? void 0 : _a.value.split('/').slice(-1)[0]) !== null && _b !== void 0 ? _b : '';
        },
        icon() {
            var _a, _b;
            const scaleOfMeasure = (_b = (_a = this.scaleOfMeasure) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
            return icons[scaleOfMeasure] || 'question-circle';
        },
    },
});
