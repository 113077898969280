
import { defineComponent } from 'vue'
import PageContent from '@/components/PageContent.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import { mapGetters, mapState } from 'vuex'
import { isRouteActive } from '../router'

export default defineComponent({
  name: 'CubeProjectView',
  components: { PageContent, LoadingBlock },

  async mounted (): Promise<void> {
    const id = this.$route.params.id
    await this.$store.dispatch('project/fetchProject', id)

    if (this.$route.name === 'CubeProject') {
      if (this.hasCSVMapping) {
        this.$router.push({ name: 'CSVMapping' })
      } else {
        this.$router.push({ name: 'Materialize' })
      }
    }
  },

  beforeUnmount (): void {
    this.$store.dispatch('project/reset')
  },

  computed: {
    ...mapState('project', {
      project: 'project',
      jobCollection: 'jobCollection',
    }),
    ...mapGetters('project', {
      hasCSVMapping: 'hasCSVMapping',
      materializeLabel: 'materializeLabel',
      transformJobs: 'transformJobs',
    }),
  },

  methods: {
    isRouteActive,
  },
})
