import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_hydra_operation_form = _resolveComponent("cc-hydra-operation-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", null, _toDisplayString(_ctx.operation.description), 1),
    _createVNode(_component_cc_hydra_operation_form, {
      ".operation": _ctx.operation,
      ".resource": _ctx.resource,
      ".shape": _ctx.shape,
      ".error": _ctx.error,
      ".submitting": _ctx.isSubmitting,
      onSubmit: _cache[0] || (_cache[0] = e => _ctx.onSubmit(e.detail.value)),
      "submit-button-variant": _ctx.submitButtonVariant
    }, null, 8, [".operation", ".resource", ".shape", ".error", ".submitting", "submit-button-variant"])
  ]))
}