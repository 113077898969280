
import { defineComponent } from 'vue'
import CubePreview from '@/components/CubePreview.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import { mapGetters, mapState } from 'vuex'

export default defineComponent({
  name: 'CubeDesignerView',
  components: { CubePreview, LoadingBlock },

  computed: {
    ...mapState('app', [
      'selectedLanguage',
    ]),
    ...mapState('project', [
      'cubeMetadata',
      'dimensionMetadataCollection',
    ]),
    ...mapGetters('project', {
      dimensions: 'dimensions',
    }),
  },

  async mounted (): Promise<void> {
    await this.$store.dispatch('project/fetchCubeMetadata')
    this.$store.dispatch('project/fetchDimensionMetadataCollection')
  },

  methods: {
    selectLanguage (language: string): void {
      this.$store.dispatch('app/selectLanguage', language)
    },

    refreshDimensions (): void {
      this.$store.dispatch('project/refreshDimensionMetadataCollection')
    },
  },
})
