
import { RuntimeOperation } from 'alcaeus'
import { defineComponent } from 'vue'
import { mapGetters, useStore } from 'vuex'

import { JobCollection } from '@cube-creator/model'

import JobForm from '@/components/JobForm.vue'
import JobItem from '@/components/JobItem.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import { usePolling } from '@/use-polling'

export default defineComponent({
  name: 'PublicationView',
  components: { LoadingBlock, JobForm, JobItem },

  setup () {
    const store = useStore()
    usePolling(() => store.dispatch('project/fetchJobCollection'))
  },

  computed: {
    ...mapGetters('project', {
      hasCSVMapping: 'hasCSVMapping',
      jobs: 'transformJobs',
    }),

    jobCollection (): JobCollection | null {
      return this.$store.state.project.jobCollection
    },

    operation (): RuntimeOperation | null {
      if (!this.jobCollection) return null

      return this.hasCSVMapping
        ? this.jobCollection.actions.createTransform
        : this.jobCollection.actions.createImport
    },
  },
})
