import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_hydra_operation_form_with_raw = _resolveComponent("cc-hydra-operation-form-with-raw")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: _ctx.title,
    onClose: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_cc_hydra_operation_form_with_raw, {
        ".operation": _ctx.operation,
        ".resource": _ctx.resource,
        ".shape": _ctx.shape,
        ".error": _ctx.error,
        ".submitting": _ctx.isSubmitting,
        "submit-label": "Save dimension",
        onSubmit: _ctx.onSubmit,
        onCancel: _ctx.onCancel
      }, null, 8, [".operation", ".resource", ".shape", ".error", ".submitting", "onSubmit", "onCancel"])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}