import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cube_preview = _resolveComponent("cube-preview")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_loading_block = _resolveComponent("loading-block")!

  return (_ctx.cubeMetadata && _ctx.dimensionMetadataCollection)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_cube_preview, {
          "cube-metadata": _ctx.cubeMetadata,
          dimensions: _ctx.dimensions,
          "dimension-metadata-collection": _ctx.dimensionMetadataCollection,
          "selected-language": _ctx.selectedLanguage,
          onSelectLanguage: _ctx.selectLanguage,
          onRefreshDimensions: _ctx.refreshDimensions
        }, null, 8, ["cube-metadata", "dimensions", "dimension-metadata-collection", "selected-language", "onSelectLanguage", "onRefreshDimensions"]),
        (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.fullPath
        }))
      ]))
    : (_openBlock(), _createBlock(_component_loading_block, { key: 1 }))
}