import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!

  return (_ctx.showLabel)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["tag is-light", _ctx.typeClass])
      }, [
        _createVNode(_component_o_icon, {
          icon: _ctx.icon,
          class: _normalizeClass(_ctx.iconColorClass)
        }, null, 8, ["icon", "class"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
      ], 2))
    : (_openBlock(), _createBlock(_component_o_icon, {
        key: 1,
        icon: _ctx.icon,
        class: _normalizeClass(_ctx.iconColorClass)
      }, null, 8, ["icon", "class"]))
}