
import { defineComponent } from 'vue'
import CsvSourceMapping from '@/components/CsvSourceMapping.vue'
import HydraOperationButton from '@/components/HydraOperationButton.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import { Table, ColumnMapping } from '@cube-creator/model'
import { mapGetters, mapState } from 'vuex'

export default defineComponent({
  name: 'CSVMappingView',
  components: { CsvSourceMapping, HydraOperationButton, LoadingBlock },

  data (): { activeArrows: string[] } {
    return {
      activeArrows: [],
    }
  },

  async mounted (): Promise<void> {
    await this.$store.dispatch('project/fetchCSVMapping')

    // TODO: Cleanup when unmounting?
    window.onresize = () => {
      this.drawArrows()
    }
  },

  computed: {
    ...mapState('project', {
      mapping: 'csvMapping',
      sourcesCollection: 'sourcesCollection',
      tableCollection: 'tableCollection',
    }),
    ...mapGetters('project', {
      sources: 'sources',
      tables: 'tables',
    }),

    columnMappings (): ColumnMapping[] {
      return this.tables.flatMap((table: Table) => table.columnMappings)
    },
  },

  methods: {
    drawArrows (): void {
      this.$nextTick(() => {
        const offsetX = this.$el.getBoundingClientRect().x
        const offsetY = this.$el.getBoundingClientRect().y

        this.columnMappings.forEach((columnMapping) => {
          const targetId = columnMapping.id.value
          const arrowEl = this.$el.querySelector(`[data-arrow-id="${targetId}"]`)
          const sourceEl = this.$el.querySelector(`[data-arrow-target="${targetId}"]`)
          const targetEl = this.$el.querySelector(`[data-column-mapping-id="${targetId}"]`)

          if (!sourceEl || !targetEl || !arrowEl) return

          const sourceBox = sourceEl.getBoundingClientRect()
          const targetBox = targetEl.getBoundingClientRect()

          const path = `
            M ${(sourceBox.x + sourceBox.width / 2) - offsetX},${(sourceBox.y + sourceBox.height / 2) - offsetY}
            L ${targetBox.x - offsetX},${(targetBox.y + targetBox.height / 2) - offsetY}
          `
          arrowEl.setAttribute('d', path)
        })
      })
    },

    highlightArrows (ids: string[]): void {
      this.activeArrows.push(...ids)
    },

    unhighlightArrows (ids: string[]): void {
      this.activeArrows = this.activeArrows.filter((id) => !ids.includes(id))
    },
  },

  watch: {
    columnMappings: {
      handler () {
        this.drawArrows()
      },
      deep: true,
    },
  },
})
