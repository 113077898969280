import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_hydra_operation_form = _resolveComponent("cc-hydra-operation-form")!

  return (_openBlock(), _createBlock(_component_cc_hydra_operation_form, {
    ".operation": _ctx.operation,
    ".resource": _ctx.resource,
    ".shape": _ctx.shape,
    ".error": _ctx.error,
    ".submitting": _ctx.isSubmitting,
    "submit-label": _ctx.submitLabel,
    onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submit', $event))),
    onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
  }, null, 8, [".operation", ".resource", ".shape", ".error", ".submitting", "submit-label"]))
}