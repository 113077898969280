
import { defineComponent, PropType } from 'vue'
import clownface from 'clownface'
import { Collection } from 'alcaeus'
import { hydra, qudt } from '@tpluscode/rdf-ns-builders'
import * as Schema from '@rdfine/schema'
import * as $rdf from '@rdf-esm/dataset'
import { debounce } from 'debounce'
import type { Cube, Dataset, DimensionMetadata, DimensionMetadataCollection } from '@cube-creator/model'
import { api } from '@/api'
import Remote, { RemoteData } from '@/remote'
import BMessage from './BMessage.vue'
import HydraOperationButton from './HydraOperationButton.vue'
import TermWithLanguage from './TermWithLanguage.vue'
import LoadingBlock from './LoadingBlock.vue'
import CubePreviewDimension from './CubePreviewDimension.vue'
import CubePreviewValue from './CubePreviewValue.vue'
import LanguageSelect from './LanguageSelect.vue'

const debounceRefreshDelay = 500

export default defineComponent({
  name: 'CubePreview',
  components: {
    BMessage,
    CubePreviewDimension,
    CubePreviewValue,
    HydraOperationButton,
    LoadingBlock,
    TermWithLanguage,
    LanguageSelect
  },
  props: {
    cubeMetadata: {
      type: Object as PropType<Dataset>,
      required: true,
    },
    dimensions: {
      type: Array as PropType<DimensionMetadata[]>,
      required: true,
    },
    dimensionMetadataCollection: {
      type: Object as PropType<DimensionMetadataCollection>,
      required: true,
    },
    selectedLanguage: {
      type: String,
      required: true,
    },
  },
  emits: ['selectLanguage', 'refreshDimensions'],

  data (): {
    pageSize: number,
    page: number,
    pageSizes: number[],
    observations: RemoteData<unknown[]>,
    totalItems: number | null,
    debouncedFetchCubeData: () => Promise<void>,
    } {
    return {
      pageSize: 10,
      page: 1,
      pageSizes: [10, 20, 50, 100],
      observations: Remote.loading(),
      totalItems: null,
      debouncedFetchCubeData: () => new Promise(resolve => resolve()),
    }
  },

  created (): void {
    this.debouncedFetchCubeData = debounce(this.fetchCubeData.bind(this), debounceRefreshDelay)
  },

  mounted (): void {
    this.fetchCubeData()
  },

  computed: {
    cube (): Cube | null {
      return this.cubeMetadata.hasPart[0] ?? null
    },

    tableWidth (): number {
      return this.dimensions.length || 1
    },

    errors (): Schema.Thing[] {
      return [
        ...(this.cubeMetadata.errors ?? []),
        ...(this.dimensionMetadataCollection.errors ?? []),
      ]
    },

    totalPages (): number | null {
      if (!this.totalItems) return null

      return Math.ceil(this.totalItems / this.pageSize)
    },

    hasPreviousPage (): boolean {
      return this.page > 1
    },

    hasNextPage (): boolean {
      return this.page < (this.totalPages ?? 0)
    },
  },

  methods: {
    dimensionClasses (dimension: DimensionMetadata): string {
      const scaleOfMeasure = dimension.scaleOfMeasure

      if (
        qudt.RatioScale.equals(scaleOfMeasure) ||
        qudt.IntervalScale.equals(scaleOfMeasure)
      ) {
        return 'has-text-right'
      }

      return ''
    },

    async refreshData (): Promise<void> {
      if (this.dimensions.length === 0) {
        this.$emit('refreshDimensions')
      }

      return this.fetchCubeData()
    },

    async fetchCubeData (): Promise<void> {
      if (this.page <= 0) {
        return
      }

      this.observations = Remote.loading()

      if (!this.cube) {
        this.observations = Remote.error('No available cube')
        return
      }

      if (!this.cube.observations) {
        // Fake wait to make it clear that something is happening
        await sleep(200)
        this.observations = Remote.loaded([])
        this.totalItems = 0
        return
      }

      const filters = clownface({ dataset: $rdf.dataset() })
        .blankNode()
        .addOut(hydra.limit, this.pageSize)
        .addOut(hydra.pageIndex, this.page)

      const uri = this.cube.observations.expand(filters)

      try {
        const collection = await api.fetchResource<Collection>(uri)
        this.totalItems = collection.totalItems ?? 0
        this.observations = Remote.loaded(collection.member)
      } catch (e: any) {
        this.observations = Remote.error(e.toString())
      }
    },
  },

  watch: {
    pageSize () {
      this.page = 1
      this.debouncedFetchCubeData()
    },

    page () {
      this.debouncedFetchCubeData()
    },
  },
})

async function sleep (duration: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, duration))
}
