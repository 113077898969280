
import { defineComponent } from 'vue'
import SignOutButton from './auth/SignOutButton.vue'
import { isRouteActive } from '../router'

export default defineComponent({
  name: 'NavBar',
  components: { SignOutButton },

  data () {
    return {
      isOpen: false,
    }
  },

  methods: {
    isRouteActive,
  },
})
