import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "has-text-grey-light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_tooltip = _resolveComponent("o-tooltip")!

  return (_openBlock(), _createBlock(_component_o_tooltip, {
    label: _ctx.displayFull,
    active: _ctx.displayShort !== _ctx.displayFull
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.displayShort), 1),
      (_ctx.showLanguage && _ctx.term.language)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "@" + _toDisplayString(_ctx.term.language), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "active"]))
}