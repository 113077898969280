
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RadioButton',
  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
    nativeValue: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],

  computed: {
    isSelected (): boolean {
      return this.modelValue === this.nativeValue
    },
  }
})
