
import { defineComponent } from 'vue'
import PageContent from '@/components/PageContent.vue'

export default defineComponent({
  name: 'NotAuthorizedView',
  components: { PageContent },

  computed: {
    link (): string {
      return this.$route.params.link as string
    },
  },
})
