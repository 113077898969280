import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_message = _resolveComponent("b-message")!
  const _component_csv_upload_form = _resolveComponent("csv-upload-form")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: _ctx.operation.title,
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_b_message, {
            key: 0,
            type: "is-danger"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.error), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_csv_upload_form, {
        "is-loading": _ctx.isLoading,
        "file-meta": _ctx.fileMeta,
        onSubmit: _ctx.submit,
        onClose: _ctx.close
      }, null, 8, ["is-loading", "file-meta", "onSubmit", "onClose"])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}