"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.CsvMappingMixin = void 0;
const rdfine_1 = require("@tpluscode/rdfine");
const namespace_1 = require("@cube-creator/core/namespace");
const initializer_1 = require("./lib/initializer");
function CsvMappingMixin(base) {
    class Impl extends base {
    }
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.cc.csvSource, values: 'array' })
    ], Impl.prototype, "sources", void 0);
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.cc.csvSourceCollection })
    ], Impl.prototype, "sourcesCollection", void 0);
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.cc.tables })
    ], Impl.prototype, "tableCollection", void 0);
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.cc.project })
    ], Impl.prototype, "project", void 0);
    return Impl;
}
exports.CsvMappingMixin = CsvMappingMixin;
CsvMappingMixin.appliesTo = namespace_1.cc.CsvMapping;
exports.create = (0, initializer_1.initializer)(CsvMappingMixin);
