import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_tooltip = _resolveComponent("o-tooltip")!

  return (_openBlock(), _createBlock(_component_o_tooltip, {
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
    label: _ctx.uri
  }, {
    default: _withCtx(() => [
      _createElementVNode("a", {
        href: _ctx.uri,
        target: "_blank",
        rel: "noopener noreferer",
        class: "button is-small is-text"
      }, [
        _createVNode(_component_o_icon, { icon: "external-link-alt" })
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["label"]))
}