import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_radio = _resolveComponent("o-radio")!

  return (_openBlock(), _createBlock(_component_o_radio, {
    "model-value": _ctx.modelValue,
    "native-value": _ctx.nativeValue,
    size: _ctx.size,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    class: _normalizeClass(["button radio-button", { 'is-primary': _ctx.isSelected }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["model-value", "native-value", "size", "class"]))
}