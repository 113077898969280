
import { dcterms, sd } from '@tpluscode/rdf-ns-builders'
import { GraphPointer } from 'clownface'
import type { NamedNode } from '@rdfjs/types'
import { defineComponent } from 'vue'
import HydraOperationButton from '@/components/HydraOperationButton.vue'
import HierarchyTree from '@/components/HierarchyTree.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import PageContent from '@/components/PageContent.vue'
import { mapState } from 'vuex'
import { BaseTree } from '@he-tree/vue3'
import { Hierarchy, NextInHierarchy } from '@/store/types'

interface Node {
  text: string
  children?: Node[]
}

export default defineComponent({
  name: 'HierarchyView',
  components: {
    HierarchyTree,
    HydraOperationButton,
    LoadingBlock,
    PageContent,
    BaseTree
  },

  mounted (): void {
    this.$store.dispatch('hierarchy/fetchHierarchy', this.$route.params.id)
  },

  unmounted () {
    this.$store.dispatch('hierarchy/reset')
  },

  computed: {
    ...mapState('hierarchy', {
      hierarchy: 'hierarchy',
    }),

    endpointUrl (): string {
      return this.hierarchy.pointer.out(dcterms.source).out(sd.endpoint).value
    },

    hierarchyRoots (): GraphPointer[] {
      return this.hierarchy.hierarchyRoot.map((term: NamedNode) => this.hierarchy.pointer.node(term))
    },

    hierarchyTree (): Node[] {
      const hierarchy: Hierarchy | null = this.hierarchy

      if (!hierarchy) {
        return []
      }

      const nextLevel = toNextLevelNode(hierarchy.nextInHierarchy)

      return [{
        text: `Dimension: ${hierarchy.dimension.value}`,
      },
      {
        text: 'Levels:',
        children: [nextLevel]
      }]
    }
  },
})

function toNextLevelNode (level: NextInHierarchy): Node {
  const children = []
  if (level.nextInHierarchy) {
    children.push(toNextLevelNode(level.nextInHierarchy))
  }

  return {
    text: `- ${level.name}`,
    children,
  }
}
