
import { defineComponent, shallowRef } from 'vue'
import { useStore } from 'vuex'

import DownloadButton from '@/components/DownloadButton.vue'
import '@/customElements/HydraOperationForm'
import { RootState } from '@/store/types'
import { confirmDialog } from '@/use-dialog'
import { useHydraForm } from '@/use-hydra-form'
import { displayToast } from '@/use-toast'
import LoadingBlock from '@/components/LoadingBlock.vue'

export default defineComponent({
  name: 'CubeProjectEditView',
  components: { DownloadButton },

  setup () {
    const store = useStore<RootState>()

    const project = store.state.project.project
    if (!project) throw new Error('Project not loaded')

    const operation = shallowRef(project.actions.edit)

    const form = useHydraForm(operation, {
      afterSubmit (project) {
        store.dispatch('project/fetchProject', project!.id.value)

        displayToast({
          message: 'Project settings were saved',
          variant: 'success',
        })
      },
    })

    if (project) {
      form.resource.value = Object.freeze(project.pointer)
    }

    return {
      ...form,
      project,
    }
  },

  methods: {
    async deleteProject (): Promise<void> {
      confirmDialog({
        title: this.project.actions.delete?.title,
        message: 'Are you sure you want to delete this project? This action is not revertible.',
        confirmText: 'Delete',
        onConfirm: async () => {
          await this.$store.dispatch('api/invokeDeleteOperation', {
            operation: this.project.actions.delete,
            successMessage: `Project ${this.project.label} successfully deleted`,
            callbackAction: 'projects/fetchCollection',
          })
          this.$router.push({ name: 'CubeProjects' })
        },
      })
    },
  },
})
