
import { defineComponent, PropType } from 'vue'
import { Job } from '@cube-creator/model'
import JobStatus from './JobStatus.vue'

export default defineComponent({
  name: 'JobItem',
  components: { JobStatus },
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
    detailView: {
      type: String,
      default: 'Job',
    },
  },

  computed: {
    createdDate (): string {
      return this.job.created.toLocaleString()
    },
  },
})
