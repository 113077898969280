"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.ImportJobMixin = void 0;
const rdfine_1 = require("@tpluscode/rdfine");
const rdfs_1 = require("@rdfine/rdfs");
const schema_1 = require("@rdfine/schema");
const namespace_1 = require("@cube-creator/core/namespace");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const initializer_1 = require("./lib/initializer");
function ImportJobMixin(base) {
    let Impl = class Impl extends (0, rdfs_1.ResourceMixin)((0, schema_1.ActionMixin)(base)) {
    };
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.cc['CubeProject/sourceCube'] })
    ], Impl.prototype, "sourceCube", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.cc['CubeProject/sourceEndpoint'] })
    ], Impl.prototype, "sourceEndpoint", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.cc['CubeProject/sourceGraph'] })
    ], Impl.prototype, "sourceGraph", void 0);
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "cubeGraph", void 0);
    __decorate([
        rdfine_1.property.resource()
    ], Impl.prototype, "dimensionMetadata", void 0);
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "dataset", void 0);
    Impl = __decorate([
        (0, rdfine_1.namespace)(namespace_1.cc)
    ], Impl);
    return Impl;
}
exports.ImportJobMixin = ImportJobMixin;
ImportJobMixin.appliesTo = namespace_1.cc.ImportJob;
exports.create = (0, initializer_1.initializer)(ImportJobMixin, {
    types: [namespace_1.cc.Job, namespace_1.cc.ImportJob],
    actionStatus: rdf_ns_builders_1.schema.PotentialActionStatus,
});
