
import { defineComponent } from 'vue'
import PageContent from '@/components/PageContent.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import HydraOperationButton from '@/components/HydraOperationButton.vue'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'HierarchiesView',
  components: { PageContent, LoadingBlock, HydraOperationButton },

  computed: {
    ...mapState('hierarchies', {
      collection: 'collection',
    }),
  },

  async mounted (): Promise<void> {
    await this.$store.dispatch('sharedDimensions/fetchEntrypoint')
    await this.$store.dispatch('hierarchies/fetchCollection')
  },
})
