import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_upload = _resolveComponent("file-upload")!
  const _component_o_tab_item = _resolveComponent("o-tab-item")!
  const _component_o_input = _resolveComponent("o-input")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_o_tabs = _resolveComponent("o-tabs")!

  return (_openBlock(), _createBlock(_component_o_tabs, {
    type: "boxed",
    modelValue: _ctx.sourceKind,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sourceKind) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_o_tab_item, {
        label: "Upload",
        value: _ctx.MediaLocal
      }, {
        default: _withCtx(() => [
          _createVNode(_component_file_upload, {
            "file-meta": _ctx.fileMeta,
            "after-upload": _ctx.submitLocal,
            onDone: _ctx.close
          }, null, 8, ["file-meta", "after-upload", "onDone"])
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_o_tab_item, {
        label: "URL",
        value: _ctx.MediaURL
      }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitUrl && _ctx.submitUrl(...args)), ["prevent"]))
          }, [
            _createVNode(_component_o_field, { label: "URL" }, {
              default: _withCtx(() => [
                _createVNode(_component_o_input, {
                  "model-value": _ctx.fileUrl,
                  type: "url",
                  required: ""
                }, null, 8, ["model-value"])
              ]),
              _: 1
            }),
            _createVNode(_component_button_loading, {
              "native-type": "submit",
              variant: "primary",
              loading: _ctx.isLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Upload ")
              ]),
              _: 1
            }, 8, ["loading"])
          ], 32)
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}