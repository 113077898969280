import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "is-flex gap-1" }
const _hoisted_2 = {
  key: 0,
  class: "tag is-warning is-light"
}
const _hoisted_3 = {
  key: 1,
  class: "tag is-light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dimension.deprecated)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " deprecated "))
      : _createCommentVNode("", true),
    (!_ctx.dimension.actions.replace)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, " read-only "))
      : _createCommentVNode("", true)
  ]))
}