import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_term_display = _resolveComponent("term-display")!
  const _component_term_with_language = _resolveComponent("term-with-language")!

  return (_openBlock(), _createBlock(_component_term_with_language, {
    values: _ctx.labels,
    "selected-language": _ctx.selectedLanguage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_term_display, {
        term: _ctx.node,
        base: _ctx.base
      }, null, 8, ["term", "base"])
    ]),
    _: 1
  }, 8, ["values", "selected-language"]))
}