import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56e35564"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "app",
  class: "is-flex is-flex-direction-column"
}
const _hoisted_2 = { class: "main is-flex-grow-1" }
const _hoisted_3 = { class: "messages" }
const _hoisted_4 = { class: "has-text-weight-bold" }
const _hoisted_5 = { class: "mt-6 px-4 py-3 has-background-light" }
const _hoisted_6 = { class: "has-text-right is-size-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_o_notification = _resolveComponent("o-notification")!
  const _component_b_loading = _resolveComponent("b-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_nav_bar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
        return (_openBlock(), _createBlock(_component_o_notification, {
          key: index,
          variant: message.variant,
          "has-icon": "",
          closable: "",
          "aria-close-label": "Dismiss",
          onClose: ($event: any) => (_ctx.dismissMessage(message)),
          "auto-close": message.variant !== 'danger',
          duration: 5000
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(message.title), 1),
            _createElementVNode("p", null, _toDisplayString(message.message), 1)
          ]),
          _: 2
        }, 1032, ["variant", "onClose", "auto-close"]))
      }), 128))
    ]),
    _createVNode(_component_b_loading, {
      active: _ctx.isLoading,
      "is-full-page": true
    }, null, 8, ["active"]),
    _createElementVNode("footer", _hoisted_5, [
      _createElementVNode("p", _hoisted_6, " version " + _toDisplayString(_ctx.release), 1)
    ])
  ]))
}