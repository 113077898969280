import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_loading = _resolveComponent("button-loading")!

  return (_ctx.resource)
    ? (_openBlock(), _createBlock(_component_button_loading, {
        key: 0,
        size: _ctx.size,
        variant: _ctx.variant,
        "icon-left": "download",
        onClick: _ctx.download,
        loading: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }, 8, ["size", "variant", "onClick", "loading"]))
    : _createCommentVNode("", true)
}