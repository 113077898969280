
import { defineComponent, shallowRef } from 'vue'
import { Project } from '@cube-creator/model'
import CubeProjectsItem from '@/components/CubeProjectsItem.vue'
import PageContent from '@/components/PageContent.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import HydraOperationButton from '@/components/HydraOperationButton.vue'
import { mapGetters, mapState } from 'vuex'
import { getRouteSearchParamsFromTemplatedOperation, isRouteActive } from '@/router'
import { useHydraForm } from '@/use-hydra-form'
import '@/customElements/HydraOperationForm'
import { rootURL } from '@/api/index'

export default defineComponent({
  name: 'CubeProjectsView',
  components: { CubeProjectsItem, PageContent, LoadingBlock, HydraOperationButton },

  async mounted (): Promise<void> {
    const emptyQuery = Object.entries(this.$route.query).length === 0

    if (emptyQuery && this.user) {
      await this.$router.replace({
        query: {
          author: `${rootURL}user/${this.user.sub}`
        }
      })
    } else {
      await this.$store.dispatch('projects/fetchCollection')
    }

    this.operation = this.projectsCollection.actions.get
    this.searchParams = this.projectsCollection.searchParams
  },

  setup () {
    const operation = shallowRef()

    const form = useHydraForm(operation)

    return {
      ...form,
      searchParams: shallowRef()
    }
  },

  computed: {
    ...mapState('projects', {
      projectsCollection: 'collection',
    }),
    ...mapGetters('auth', {
      user: 'oidcUser',
    }),

    projects (): Project[] {
      return this.projectsCollection?.member ?? []
    },
  },

  async beforeRouteUpdate (to) {
    await this.$store.dispatch('projects/fetchCollection', to.query)
    this.searchParams = this.projectsCollection.searchParams
  },

  methods: {
    isRouteActive,

    onSearch (e: CustomEvent) {
      if (this.operation && e.detail?.value) {
        this.$router.push({
          query: getRouteSearchParamsFromTemplatedOperation(this.operation, e.detail?.value),
        })
      }
    }
  },
})
