import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_tooltip = _resolveComponent("o-tooltip")!

  return (_ctx.operation)
    ? (_openBlock(), _createBlock(_component_o_tooltip, {
        key: 0,
        label: _ctx.operation.title
      }, {
        default: _withCtx(() => [
          _createVNode(_component_o_button, {
            tag: _ctx.tag,
            to: _ctx.to,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
            variant: _ctx.variant,
            size: _ctx.size,
            "icon-left": _ctx.iconName,
            disabled: _ctx.disabled,
            label: _ctx.label
          }, null, 8, ["tag", "to", "variant", "size", "icon-left", "disabled", "label"])
        ]),
        _: 1
      }, 8, ["label"]))
    : _createCommentVNode("", true)
}