import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-041abd78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quickview-inner" }
const _hoisted_2 = { class: "quickview-header" }
const _hoisted_3 = { class: "quickview-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["quickview", { 'is-active': _ctx.isOpen }]),
    style: _normalizeStyle(_ctx.width ? { width: _ctx.width } : {})
  }, [
    _createElementVNode("div", {
      onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args))),
      class: "handle"
    }, null, 32),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("button", {
          class: "delete",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ], 6))
}