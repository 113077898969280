"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'ButtonLoading',
    props: {
        nativeType: {
            type: String,
            default: undefined,
        },
        variant: {
            type: String,
            default: undefined,
        },
        size: {
            type: String,
            default: undefined,
        },
        iconLeft: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        icon() {
            if (this.loading) {
                return 'spinner';
            }
            else {
                return this.iconLeft;
            }
        },
        iconClass() {
            return this.loading
                ? 'animate-spin'
                : '';
        },
    }
});
