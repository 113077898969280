"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.DatasetMixin = exports.Error = void 0;
const rdfine_1 = require("@tpluscode/rdfine");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const namespace_1 = require("@cube-creator/core/namespace");
const initializer_1 = require("./lib/initializer");
const DimensionMetadata_1 = require("./DimensionMetadata");
const resourceIdentifiers_1 = require("./lib/resourceIdentifiers");
exports.Error = {
    MissingObservationValues: 'missing-values-error',
    MultipleDimensionValues: 'non-unique-observations',
};
function DatasetMixin(Resource) {
    class Impl extends Resource {
    }
    __decorate([
        rdfine_1.property.resource({ path: rdf_ns_builders_1.schema.hasPart, values: 'array', implicitTypes: [namespace_1.cube.Cube] })
    ], Impl.prototype, "hasPart", void 0);
    __decorate([
        rdfine_1.property.resource({
            path: namespace_1.cc.dimensionMetadata,
            as: [DimensionMetadata_1.DimensionMetadataCollectionMixin],
            initial: (0, resourceIdentifiers_1.childResource)('dimension-metadata'),
        })
    ], Impl.prototype, "dimensionMetadata", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: rdf_ns_builders_1.dcterms.title, values: 'array' })
    ], Impl.prototype, "title", void 0);
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.dcterms.issued, type: Date, datatype: rdf_ns_builders_1.xsd.date })
    ], Impl.prototype, "created", void 0);
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.schema.datePublished, type: Date, datatype: rdf_ns_builders_1.xsd.date })
    ], Impl.prototype, "published", void 0);
    return Impl;
}
exports.DatasetMixin = DatasetMixin;
DatasetMixin.appliesTo = rdf_ns_builders_1._void.Dataset;
exports.create = (0, initializer_1.initializer)(DatasetMixin, () => {
    const created = new Date();
    return {
        types: [rdf_ns_builders_1._void.Dataset, rdf_ns_builders_1.schema.Dataset, rdf_ns_builders_1.dcat.Dataset],
        created,
        [rdf_ns_builders_1.schema.dateCreated.value]: {
            value: created,
            datatype: rdf_ns_builders_1.xsd.date,
        },
    };
});
