
import { defineComponent } from 'vue'
import { supportedLanguages } from '@cube-creator/core/languages'

export default defineComponent({
  name: 'LanguageSelect',

  props: {
    selectedLanguage: {
      type: String,
      default: 'en'
    }
  },

  emits: ['language-selected'],

  data () {
    return {
      languages: supportedLanguages.map(({ value }) => value),
    }
  }
})
