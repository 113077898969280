
import { ColorsModifiers } from '@oruga-ui/oruga/types/helpers'
import { defineComponent, PropType } from 'vue'
import { RuntimeOperation } from 'alcaeus'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'HydraOperationButton',
  props: {
    operation: {
      type: Object as PropType<RuntimeOperation>,
      default: null,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
      default: null,
    },
    variant: {
      type: String as PropType<ColorsModifiers>,
      default: 'text',
    },
    size: {
      type: String,
      default: 'small',
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  emits: ['click'],

  computed: {
    tag (): string {
      return this.to ? 'router-link' : 'button'
    },

    iconName (): string | null {
      return this.icon || guessIcon(this.operation)
    },
  },
})

function guessIcon (operation: RuntimeOperation | null): string | null {
  if (!operation) return null

  switch (operation.method) {
    case 'POST':
      return 'plus'
    case 'PATCH':
    case 'PUT':
      return 'pencil-alt'
    case 'DELETE':
      return 'trash'
    default:
      return null
  }
}
