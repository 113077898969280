import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_autocomplete = _resolveComponent("o-autocomplete")!
  const _component_o_field = _resolveComponent("o-field")!

  return (_openBlock(), _createBlock(_component_o_field, { message: _ctx.fullURI }, {
    default: _withCtx(() => [
      _createVNode(_component_o_autocomplete, {
        "model-value": _ctx.textValue,
        "onUpdate:modelValue": _ctx.onUpdate,
        data: _ctx.suggestions
      }, null, 8, ["model-value", "onUpdate:modelValue", "data"])
    ]),
    _: 1
  }, 8, ["message"]))
}