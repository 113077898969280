import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_term_display = _resolveComponent("term-display")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_cc_hydra_operation_form_with_raw = _resolveComponent("cc-hydra-operation-form-with-raw")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: _ctx.title,
    onClose: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_o_field, { label: "Dimension property" }, {
        default: _withCtx(() => [
          (_ctx.dimension.about)
            ? (_openBlock(), _createBlock(_component_term_display, {
                key: 0,
                term: _ctx.dimension.about,
                base: _ctx.cubeUri
              }, null, 8, ["term", "base"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.resource && _ctx.operation)
        ? (_openBlock(), _createBlock(_component_cc_hydra_operation_form_with_raw, {
            key: 0,
            ".operation": _ctx.operation,
            ".resource": _ctx.resource,
            ".shape": _ctx.shape,
            ".error": _ctx.error,
            ".submitting": _ctx.isSubmitting,
            "submit-label": "Update dimension metadata",
            onSubmit: _ctx.onSubmit,
            onCancel: _ctx.onCancel
          }, null, 8, [".operation", ".resource", ".shape", ".error", ".submitting", "onSubmit", "onCancel"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}