import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-020f7f27"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "tabs is-boxed mb-0 mt-2" }
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = { class: "project-title has-text-weight-bold truncate" }
const _hoisted_5 = ["href", "onClick"]
const _hoisted_6 = ["href", "onClick"]
const _hoisted_7 = ["href", "onClick"]
const _hoisted_8 = ["href", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_tooltip = _resolveComponent("o-tooltip")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_content = _resolveComponent("page-content")!
  const _component_loading_block = _resolveComponent("loading-block")!

  return (_ctx.project)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", null, [
            _createVNode(_component_router_link, {
              to: { name: 'CubeProjectEdit' },
              custom: ""
            }, {
              default: _withCtx(({ href, navigate, route }) => [
                _createElementVNode("li", {
                  class: _normalizeClass({ 'is-active': _ctx.isRouteActive(route, _ctx.$route) })
                }, [
                  _createVNode(_component_o_tooltip, { label: "Project settings" }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: href,
                        onClick: navigate
                      }, [
                        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.project.title), 1),
                        _createVNode(_component_o_icon, {
                          icon: "cog",
                          class: "ml-1"
                        })
                      ], 8, _hoisted_3)
                    ]),
                    _: 2
                  }, 1024)
                ], 2)
              ]),
              _: 1
            }),
            (_ctx.hasCSVMapping)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: { name: 'CSVMapping' },
                  custom: ""
                }, {
                  default: _withCtx(({ href, navigate, route }) => [
                    _createElementVNode("li", {
                      class: _normalizeClass({ 'is-active': _ctx.isRouteActive(route, _ctx.$route) })
                    }, [
                      _createElementVNode("a", {
                        href: href,
                        onClick: navigate
                      }, "1. CSV Mapping", 8, _hoisted_5)
                    ], 2)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_router_link, {
              to: { name: 'Materialize' },
              custom: ""
            }, {
              default: _withCtx(({ href, navigate, route }) => [
                _createElementVNode("li", {
                  class: _normalizeClass({ 'is-active': _ctx.isRouteActive(route, _ctx.$route) })
                }, [
                  _createElementVNode("a", {
                    href: href,
                    onClick: navigate,
                    class: "is-capitalized"
                  }, "2. " + _toDisplayString(_ctx.materializeLabel), 9, _hoisted_6)
                ], 2)
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: { name: 'CubeDesigner' },
              custom: ""
            }, {
              default: _withCtx(({ href, navigate, route }) => [
                _createElementVNode("li", {
                  class: _normalizeClass({ 'is-active': _ctx.isRouteActive(route, _ctx.$route) })
                }, [
                  _createElementVNode("a", {
                    href: href,
                    onClick: navigate
                  }, "3. Cube Designer", 8, _hoisted_7)
                ], 2)
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: { name: 'Publication' },
              custom: ""
            }, {
              default: _withCtx(({ href, navigate, route }) => [
                _createElementVNode("li", {
                  class: _normalizeClass({ 'is-active': _ctx.isRouteActive(route, _ctx.$route) })
                }, [
                  _createElementVNode("a", {
                    href: href,
                    onClick: navigate
                  }, " 4. Publication ", 8, _hoisted_8)
                ], 2)
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_page_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createBlock(_component_loading_block, { key: 1 }))
}