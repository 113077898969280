"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseResourceMixin = void 0;
const rdfine_1 = require("@tpluscode/rdfine");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const namespace_1 = require("../core/namespace");
const Job_1 = require("./Job");
function BaseResourceMixin(base) {
    let Impl = class Impl extends base {
        addError(errorOrFactory) {
            const error = errorOrFactory(this.pointer.blankNode());
            if (this.errors.some(current => current.identifierLiteral === error.identifierLiteral)) {
                return;
            }
            this.errors = [
                ...this.errors,
                error,
            ];
        }
        removeError(id) {
            this.pointer
                .out(rdf_ns_builders_1.schema.error)
                .has(rdf_ns_builders_1.schema.identifier, id)
                .deleteOut()
                .deleteIn();
        }
    };
    __decorate([
        rdfine_1.property.resource({
            path: rdf_ns_builders_1.schema.error,
            values: 'array',
            as: [Job_1.JobErrorMixin],
            filter: term => term.termType === 'NamedNode' || term.termType === 'BlankNode',
        })
    ], Impl.prototype, "errors", void 0);
    Impl = __decorate([
        (0, rdfine_1.namespace)(namespace_1.cc)
    ], Impl);
    return Impl;
}
exports.BaseResourceMixin = BaseResourceMixin;
BaseResourceMixin.shouldApply = true;
