
import type { CreativeWork } from '@rdfine/schema'
import { schema } from '@tpluscode/rdf-ns-builders'
import { computed, defineComponent, shallowRef } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { api } from '@/api'
import BMessage from '@/components/BMessage.vue'
import ExternalTerm from '@/components/ExternalTerm.vue'
import JobStatus from '@/components/JobStatus.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import '@/customElements/MarkdownRender'
import ValidationReportDisplay from '@/components/ValidationReportDisplay.vue'
import { RootState } from '@/store/types'
import { usePolling } from '@/use-polling'

export default defineComponent({
  name: 'JobView',
  components: { BMessage, ExternalTerm, JobStatus, LoadingBlock, ValidationReportDisplay },

  setup () {
    const store = useStore<RootState>()
    const route = useRoute()

    const findJob = store.getters['project/findJob']
    const jobId = route.params.jobId as string
    const job = shallowRef(findJob(jobId))
    const link = computed(() => job.value?.link?.id.value)
    const createdDate = computed(() => job.value?.created.toLocaleString() ?? '')
    const error = computed(() => job.value?.errors[0] || {})

    usePolling(async () => {
      job.value = await api.fetchResource(jobId)
    })

    const language = store.state.app.language

    return {
      job,
      error,
      language,
      link,
      createdDate,
    }
  },

  methods: {
    workExampleLabel (workExample: CreativeWork): string {
      return workExample.pointer.out(schema.name, { language: this.language }).value || 'Example'
    },
  },
})
