
import { defineComponent, PropType } from 'vue'
import { SharedDimension } from '@/store/types'

export default defineComponent({
  name: 'SharedDimensionTags',
  props: {
    dimension: {
      type: Object as PropType<SharedDimension>,
      required: true,
    }
  },
})
