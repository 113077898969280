import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-1" }
const _hoisted_2 = { class: "box container-narrow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_button = _resolveComponent("o-button")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_o_button, {
        tag: "router-link",
        to: { name: 'Materialize' },
        "icon-left": "arrow-left",
        size: "small"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Back to " + _toDisplayString(_ctx.materializeLabel), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ]))
}