import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_literal_column_mapping_form = _resolveComponent("literal-column-mapping-form")!
  const _component_reference_column_mapping_form = _resolveComponent("reference-column-mapping-form")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: _ctx.title,
    onClose: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      (_ctx.isLiteral)
        ? (_openBlock(), _createBlock(_component_literal_column_mapping_form, {
            key: 0,
            operation: _ctx.operation,
            "column-mapping": _ctx.columnMapping,
            table: _ctx.table,
            source: _ctx.source,
            error: _ctx.error,
            "is-submitting": _ctx.isSubmitting,
            "submit-label": "Update column mapping",
            onSubmit: _ctx.onSubmit,
            onCancel: _ctx.onCancel
          }, null, 8, ["operation", "column-mapping", "table", "source", "error", "is-submitting", "onSubmit", "onCancel"]))
        : (_openBlock(), _createBlock(_component_reference_column_mapping_form, {
            key: 1,
            "column-mapping": _ctx.columnMapping,
            table: _ctx.table,
            source: _ctx.source,
            operation: _ctx.operation,
            error: _ctx.error,
            "is-submitting": _ctx.isSubmitting,
            "submit-label": "Update link",
            onSubmit: _ctx.onSubmit,
            onCancel: _ctx.onCancel
          }, null, 8, ["column-mapping", "table", "source", "operation", "error", "is-submitting", "onSubmit", "onCancel"]))
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}