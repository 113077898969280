
import { defineComponent, PropType } from 'vue'
import { ValidationReport } from '@rdfine/shacl'
import ValidationResultDisplay from './ValidationResultDisplay.vue'

export default defineComponent({
  name: 'ValidationReportDisplay',
  components: { ValidationResultDisplay },
  props: {
    report: {
      type: Object as PropType<ValidationReport>,
      required: true,
    },
  },
})
