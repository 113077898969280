import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_radio_button = _resolveComponent("radio-button")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_literal_column_mapping_form = _resolveComponent("literal-column-mapping-form")!
  const _component_reference_column_mapping_form = _resolveComponent("reference-column-mapping-form")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: _ctx.title,
    onClose: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_o_field, { label: "Column mapping type" }, {
        default: _withCtx(() => [
          _createVNode(_component_radio_button, {
            modelValue: _ctx.columnMappingType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.columnMappingType) = $event)),
            "native-value": "literal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Literal value ")
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_radio_button, {
            modelValue: _ctx.columnMappingType,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.columnMappingType) = $event)),
            "native-value": "reference"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Link to another table ")
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      (_ctx.columnMappingType === 'literal')
        ? (_openBlock(), _createBlock(_component_literal_column_mapping_form, {
            key: 0,
            operation: _ctx.operation,
            error: _ctx.error,
            "is-submitting": _ctx.isSubmitting,
            "submit-label": "Create column mapping",
            table: _ctx.table,
            source: _ctx.source,
            onSubmit: _ctx.onSubmit,
            onCancel: _ctx.onCancel
          }, null, 8, ["operation", "error", "is-submitting", "table", "source", "onSubmit", "onCancel"]))
        : (_ctx.columnMappingType === 'reference')
          ? (_openBlock(), _createBlock(_component_reference_column_mapping_form, {
              key: 1,
              operation: _ctx.operation,
              error: _ctx.error,
              "is-submitting": _ctx.isSubmitting,
              "submit-label": "Create link",
              table: _ctx.table,
              source: _ctx.source,
              onSubmit: _ctx.onSubmit,
              onCancel: _ctx.onCancel
            }, null, 8, ["operation", "error", "is-submitting", "table", "source", "onSubmit", "onCancel"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}