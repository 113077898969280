
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BLoading',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    isFullPage: {
      type: Boolean,
      default: true,
    },
    animation: {
      type: String,
      default: 'fade',
    }
  },
})
