
import { defineComponent } from 'vue'
import PageContent from '@/components/PageContent.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import HydraOperationButton from '@/components/HydraOperationButton.vue'
import SharedDimensionTags from '@/components/SharedDimensionTags.vue'
import { mapGetters, mapState } from 'vuex'
import LanguageSelect from '@/components/LanguageSelect.vue'
import TermWithLanguage from '@/components/TermWithLanguage.vue'
import { SharedDimension } from '@/store/types'

export default defineComponent({
  name: 'CubeProjectsView',
  components: {
    TermWithLanguage,
    PageContent,
    LoadingBlock,
    HydraOperationButton,
    SharedDimensionTags,
    LanguageSelect
  },

  data () {
    return {
      showAll: false
    }
  },

  async mounted (): Promise<void> {
    await this.$store.dispatch('sharedDimensions/fetchEntrypoint')
    await this.$store.dispatch('sharedDimensions/fetchCollection')
  },

  computed: {
    ...mapState('app', [
      'selectedLanguage',
    ]),
    ...mapState('sharedDimensions', {
      collection: 'collection',
    }),
    ...mapGetters('sharedDimensions', {
      dimensions: 'dimensions',
    }),
    displayed (): SharedDimension[] {
      if (this.showAll) { return this.dimensions }

      return this.dimensions
        .filter((dimension: any) => !dimension.deprecated)
    },
  },

  methods: {
    selectLanguage (language: string): void {
      this.$store.dispatch('app/selectLanguage', language)
    },

    toggleDisplay () {
      this.showAll = !this.showAll
    }
  }
})
