"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const labels = {
    [rdf_ns_builders_1.time.GeneralDateTimeDescription.value]: 'Time description',
    [rdf_ns_builders_1.schema.GeoCoordinates.value]: 'Geographic coordinates',
    [rdf_ns_builders_1.schema.GeoShape.value]: 'Geographic shape',
};
const icons = {
    [rdf_ns_builders_1.time.GeneralDateTimeDescription.value]: 'clock',
    [rdf_ns_builders_1.schema.GeoCoordinates.value]: 'map',
    [rdf_ns_builders_1.schema.GeoShape.value]: 'map-marked-alt',
};
exports.default = (0, vue_1.defineComponent)({
    name: 'DataKindIcon',
    props: {
        dataKind: {
            type: Object,
            default: undefined,
        }
    },
    computed: {
        dataKindURI() {
            var _a, _b;
            return (_b = (_a = this.dataKind) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
        },
        label() {
            return labels[this.dataKindURI] || this.dataKindURI;
        },
        icon() {
            return icons[this.dataKindURI] || 'question-circle';
        },
    },
});
