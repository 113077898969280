import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_cc_hydra_operation_form_with_raw = _resolveComponent("cc-hydra-operation-form-with-raw")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: _ctx.title,
    onClose: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      (_ctx.termUri)
        ? (_openBlock(), _createBlock(_component_o_field, {
            key: 0,
            label: "URI"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                class: "form-input",
                href: _ctx.termUri,
                target: "_blank",
                rel: "noopener noreferer"
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.termUri), 1),
                _createVNode(_component_o_icon, { icon: "external-link-alt" })
              ], 8, _hoisted_1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_cc_hydra_operation_form_with_raw, {
        ".operation": _ctx.operation,
        ".resource": _ctx.resource,
        ".shape": _ctx.shape,
        ".error": _ctx.error,
        ".submitting": _ctx.isSubmitting,
        "submit-label": "Save term",
        onSubmit: _ctx.onSubmit,
        onCancel: _ctx.onCancel
      }, null, 8, [".operation", ".resource", ".shape", ".error", ".submitting", "onSubmit", "onCancel"])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}