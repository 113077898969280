import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba57c5a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "table columns-table" }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_select = _resolveComponent("o-select")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_property_input = _resolveComponent("property-input")!
  const _component_loading_block = _resolveComponent("loading-block")!
  const _component_hydra_operation_error = _resolveComponent("hydra-operation-error")!
  const _component_form_submit_cancel = _resolveComponent("form-submit-cancel")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createVNode(_component_o_field, { label: "Link to table" }, {
      default: _withCtx(() => [
        _createVNode(_component_o_select, {
          modelValue: _ctx.data.referencedTable,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.referencedTable) = $event)),
          placeholder: "Select a table"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tables, (optionTable) => {
              return (_openBlock(), _createElementBlock("option", {
                key: optionTable.clientPath,
                value: optionTable
              }, _toDisplayString(optionTable.name), 9, _hoisted_1))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_o_field, { label: "Using the property" }, {
      default: _withCtx(() => [
        _createVNode(_component_property_input, {
          value: _ctx.data.targetProperty,
          update: (value) => _ctx.data.targetProperty = value
        }, null, 8, ["value", "update"])
      ]),
      _: 1
    }),
    (_ctx.data.referencedTable)
      ? (_openBlock(), _createBlock(_component_o_field, {
          key: 0,
          label: "Identifier mapping",
          addons: false
        }, {
          default: _withCtx(() => [
            (_ctx.data.identifierMapping)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.data.identifierMapping)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                        _createTextVNode(" The identifier "),
                        _createElementVNode("code", null, _toDisplayString(_ctx.data.referencedTable.identifierTemplate), 1),
                        _createTextVNode(" will take its values from the column" + _toDisplayString(_ctx.data.identifierMapping.length === 1 ? '' : 's'), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("table", _hoisted_4, [
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.identifierMapping, (mapping) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: mapping.referencedColumn.id.value
                        }, [
                          _createElementVNode("td", null, [
                            _createVNode(_component_o_select, {
                              modelValue: mapping.sourceColumnId,
                              "onUpdate:modelValue": ($event: any) => ((mapping.sourceColumnId) = $event)
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source.columns, (column) => {
                                  return (_openBlock(), _createElementBlock("option", {
                                    key: column.clientPath,
                                    value: column.id.value
                                  }, _toDisplayString(column.name), 9, _hoisted_5))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _createElementVNode("td", null, [
                            _createTextVNode("for "),
                            _createElementVNode("code", null, _toDisplayString('{' + _ctx.getColumn(_ctx.data.referencedTable.id, mapping.referencedColumn.id).name + '}'), 1)
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ]))
              : (_openBlock(), _createBlock(_component_loading_block, { key: 1 }))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_hydra_operation_error, {
      error: _ctx.error,
      class: "mt-4"
    }, null, 8, ["error"]),
    _createVNode(_component_form_submit_cancel, {
      "submit-label": _ctx.submitLabel,
      "is-submitting": _ctx.isSubmitting,
      onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel'))),
      onCcSubmit: _ctx.onSubmit
    }, null, 8, ["submit-label", "is-submitting", "onCcSubmit"])
  ]))
}