
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BMessage',
  props: {
    type: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
  },
})
