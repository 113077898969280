"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const rdf_properties_1 = require("@/rdf-properties");
exports.default = (0, vue_1.defineComponent)({
    name: 'PropertyDisplay',
    props: {
        term: {
            type: Object,
            required: true,
        }
    },
    computed: {
        createIdentifier() {
            return this.$store.state.project.createIdentifier;
        },
        value() {
            return this.term.value || '';
        },
        expanded() {
            return this.createIdentifier
                ? this.createIdentifier(this.term)
                : this.term.value;
        },
        shrunk() {
            return (0, rdf_properties_1.shrink)(this.value);
        },
    },
});
