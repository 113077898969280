import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "has-text-grey"
}
const _hoisted_2 = {
  key: 3,
  class: "has-background-danger-light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_external_term = _resolveComponent("external-term")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_term_display = _resolveComponent("term-display")!

  return (!_ctx.value)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.missingValue), 1))
    : (_ctx.isResource)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: { name: 'ResourcePreview', params: { resourceId: _ctx.value.id.value, sharedTerm: _ctx.isSharedTerm } },
          class: "tag is-rounded is-small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_external_term, {
              resource: _ctx.value,
              base: _ctx.cubeUri,
              "selected-language": _ctx.selectedLanguage
            }, null, 8, ["resource", "base", "selected-language"])
          ]),
          _: 1
        }, 8, ["to"]))
      : (_ctx.isTerm)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: _normalizeClass(_ctx.termClasses)
          }, [
            _createVNode(_component_term_display, {
              term: _ctx.value,
              base: _ctx.cubeUri,
              "show-language": _ctx.showLanguage
            }, null, 8, ["term", "base", "show-language"])
          ], 2))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, " Cannot display value "))
}