"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'DialogConfirm',
    props: {
        title: {
            type: String,
            default: 'Are you sure?',
        },
        message: {
            type: String,
            required: true,
        },
        confirmText: {
            type: String,
            default: 'Confirm',
        },
        variant: {
            type: String,
            default: 'danger',
        },
        hasIcon: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['confirm', 'cancel', 'close'],
    computed: {
        icon() {
            if (!this.hasIcon) {
                return null;
            }
            switch (this.variant) {
                case 'info':
                    return 'information';
                case 'success':
                    return 'check-circle';
                case 'warning':
                    return 'alert';
                case 'danger':
                    return 'alert-circle';
                default:
                    return null;
            }
        },
    },
    methods: {
        onConfirm() {
            this.$emit('confirm');
            this.$emit('close');
        },
        onCancel() {
            this.$emit('cancel');
            this.$emit('close');
        },
    },
});
