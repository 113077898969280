"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.CubeMixin = exports.Published = exports.Draft = void 0;
const rdfine_1 = require("@tpluscode/rdfine");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const namespace_1 = require("@cube-creator/core/namespace");
const data_model_1 = require("@rdf-esm/data-model");
const initializer_1 = require("./lib/initializer");
exports.Draft = (0, data_model_1.namedNode)('https://ld.admin.ch/vocabulary/CreativeWorkStatus/Draft');
exports.Published = (0, data_model_1.namedNode)('https://ld.admin.ch/vocabulary/CreativeWorkStatus/Published');
function CubeMixin(Resource) {
    class Impl extends Resource {
    }
    __decorate([
        rdfine_1.property.literal({
            path: rdf_ns_builders_1.schema.dateCreated,
            datatype: rdf_ns_builders_1.xsd.date,
            type: Date,
            initial: () => new Date(),
        })
    ], Impl.prototype, "dateCreated", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: rdf_ns_builders_1.dcterms.creator })
    ], Impl.prototype, "creator", void 0);
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.cc.observations })
    ], Impl.prototype, "observations", void 0);
    return Impl;
}
exports.CubeMixin = CubeMixin;
CubeMixin.appliesTo = namespace_1.cube.Cube;
exports.create = (0, initializer_1.initializer)(CubeMixin, {
    types: [namespace_1.cube.Cube],
});
