import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_hydra_operation_form = _resolveComponent("cc-hydra-operation-form")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: _ctx.title,
    onClose: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      (_ctx.operation)
        ? (_openBlock(), _createBlock(_component_cc_hydra_operation_form, {
            key: 0,
            ".operation": _ctx.operation,
            ".resource": _ctx.resource,
            ".shape": _ctx.shape,
            ".error": _ctx.error,
            ".submitting": _ctx.isSubmitting,
            "submit-label": "Create project",
            onSubmit: _ctx.onSubmit,
            onCancel: _ctx.onCancel
          }, null, 8, [".operation", ".resource", ".shape", ".error", ".submitting", "onSubmit", "onCancel"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}