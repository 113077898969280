import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_cc_hydra_operation_form = _resolveComponent("cc-hydra-operation-form")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: _ctx.title,
    onClose: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      (_ctx.operation && _ctx.resource)
        ? (_openBlock(), _createBlock(_component_cc_hydra_operation_form, {
            key: 0,
            ".operation": _ctx.operation,
            ".resource": _ctx.resource,
            ".shape": _ctx.shape,
            ".error": _ctx.error,
            ".submitting": _ctx.isSubmitting,
            onCancel: _ctx.onCancel,
            onSubmit: _ctx.onSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_o_field, { addons: false }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    (_ctx.batchMappingOperation)
                      ? (_openBlock(), _createBlock(_component_o_button, {
                          key: 0,
                          variant: "default",
                          "icon-left": "magic",
                          onClick: _ctx.importTerms
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Auto-fill from Shared Dimension ")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, [".operation", ".resource", ".shape", ".error", ".submitting", "onCancel", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}