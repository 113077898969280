"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.fromPointer = exports.TableMixin = void 0;
const rdfine_1 = __importStar(require("@tpluscode/rdfine"));
const namespace_1 = require("@cube-creator/core/namespace");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const initializer_1 = require("./lib/initializer");
const resourceIdentifiers_1 = require("./lib/resourceIdentifiers");
const uriTemplateParser_1 = require("./lib/uriTemplateParser");
function TableMixin(base) {
    let Impl = class Impl extends base {
        get isObservationTable() {
            return this.types.has(namespace_1.cc.ObservationTable);
        }
        get parsedTemplate() {
            var _a;
            return (0, uriTemplateParser_1.parse)((_a = this.identifierTemplate) !== null && _a !== void 0 ? _a : '');
        }
    };
    __decorate([
        rdfine_1.property.resource({ initial: (0, resourceIdentifiers_1.childResource)('csvw') })
    ], Impl.prototype, "csvw", void 0);
    __decorate([
        rdfine_1.property.resource()
    ], Impl.prototype, "csvSource", void 0);
    __decorate([
        rdfine_1.property.resource()
    ], Impl.prototype, "csvMapping", void 0);
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.schema.name })
    ], Impl.prototype, "name", void 0);
    __decorate([
        rdfine_1.property.literal()
    ], Impl.prototype, "identifierTemplate", void 0);
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.schema.color })
    ], Impl.prototype, "color", void 0);
    Impl = __decorate([
        (0, rdfine_1.namespace)(namespace_1.cc)
    ], Impl);
    return Impl;
}
exports.TableMixin = TableMixin;
TableMixin.appliesTo = namespace_1.cc.Table;
const fromPointer = (pointer) => {
    return rdfine_1.default.factory.createEntity(pointer, [TableMixin]);
};
exports.fromPointer = fromPointer;
exports.create = (0, initializer_1.initializer)(TableMixin, {
    types: [namespace_1.cc.Table],
});
