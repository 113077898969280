import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_result_display = _resolveComponent("validation-result-display")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report.result, (result) => {
      return (_openBlock(), _createBlock(_component_validation_result_display, {
        tag: "li",
        key: result.clientPath,
        result: result
      }, null, 8, ["result"]))
    }), 128))
  ]))
}