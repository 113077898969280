import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_select = _resolveComponent("o-select")!

  return (_openBlock(), _createBlock(_component_o_select, {
    "model-value": _ctx.selectedLanguage,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('language-selected', $event))),
    class: "level-item",
    title: "Language"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
        return (_openBlock(), _createElementBlock("option", {
          key: language,
          value: language
        }, _toDisplayString(language), 9, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }, 8, ["model-value"]))
}