"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.createCollection = exports.dimensionChangedWarning = exports.createNoMeasureDimensionError = exports.Error = exports.DimensionMetadataCollectionMixin = void 0;
const Rdfs = __importStar(require("@rdfine/rdfs"));
const rdfine_1 = require("@tpluscode/rdfine");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const Thing = __importStar(require("@rdfine/schema/lib/Thing"));
const namespace_1 = require("@cube-creator/core/namespace");
const initializer_1 = require("./lib/initializer");
require("./BaseResource");
function DimensionMetadataMixin(base) {
    let Impl = class Impl extends Rdfs.ResourceMixin(base) {
        get isMeasureDimension() {
            return this.types.has(namespace_1.cube.MeasureDimension);
        }
        get isKeyDimension() {
            return this.types.has(namespace_1.cube.KeyDimension);
        }
    };
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "about", void 0);
    __decorate([
        (0, rdfine_1.property)({ values: 'array' })
    ], Impl.prototype, "name", void 0);
    __decorate([
        (0, rdfine_1.property)({ values: 'array' })
    ], Impl.prototype, "description", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: rdf_ns_builders_1.qudt.scaleType })
    ], Impl.prototype, "scaleOfMeasure", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.meta.dataKind })
    ], Impl.prototype, "dataKind", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.cc.dimensionMapping })
    ], Impl.prototype, "mappings", void 0);
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.sh.order, type: Number })
    ], Impl.prototype, "order", void 0);
    Impl = __decorate([
        (0, rdfine_1.namespace)(rdf_ns_builders_1.schema)
    ], Impl);
    return Impl;
}
function DimensionMetadataCollectionMixin(Resource) {
    class Impl extends Resource {
    }
    __decorate([
        rdfine_1.property.resource({ path: rdf_ns_builders_1.schema.hasPart, values: 'array', as: [DimensionMetadataMixin] })
    ], Impl.prototype, "hasPart", void 0);
    return Impl;
}
exports.DimensionMetadataCollectionMixin = DimensionMetadataCollectionMixin;
DimensionMetadataCollectionMixin.appliesTo = namespace_1.cc.DimensionMetadataCollection;
exports.Error = {
    MissingMeasureDimension: 'MissingMeasureDimension',
    DimensionMappingChanged: 'DimensionMappingChanged',
};
exports.createNoMeasureDimensionError = Thing.fromPointer({
    identifierLiteral: exports.Error.MissingMeasureDimension,
    description: 'No Measure dimension defined',
});
exports.dimensionChangedWarning = Thing.fromPointer({
    identifierLiteral: exports.Error.DimensionMappingChanged,
    description: 'Dimension mappings changed. It may be necessary to run transformation',
});
exports.createCollection = (0, initializer_1.initializer)(DimensionMetadataCollectionMixin, {
    types: [namespace_1.cc.DimensionMetadataCollection],
    [rdf_ns_builders_1.schema.error.value]: [exports.createNoMeasureDimensionError],
});
exports.create = (0, initializer_1.initializer)(DimensionMetadataMixin);
