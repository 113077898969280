"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLiteralColumnMapping = exports.createIdentifierMapping = exports.createReference = exports.createLiteral = exports.referenceFromPointer = exports.literalFromPointer = exports.ReferenceColumnMappingMixin = exports.IdentifierMappingMixin = exports.LiteralColumnMappingMixin = exports.ColumnMappingMixin = void 0;
const rdfine_1 = __importStar(require("@tpluscode/rdfine"));
const namespace_1 = require("@cube-creator/core/namespace");
const initializer_1 = require("./lib/initializer");
function ColumnMappingMixin(Resource) {
    let Impl = class Impl extends Resource {
    };
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "targetProperty", void 0);
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "dimensionType", void 0);
    Impl = __decorate([
        (0, rdfine_1.namespace)(namespace_1.cc)
    ], Impl);
    return Impl;
}
exports.ColumnMappingMixin = ColumnMappingMixin;
ColumnMappingMixin.appliesTo = namespace_1.cc.ColumnMapping;
function LiteralColumnMappingMixin(Resource) {
    let Impl = class Impl extends Resource {
    };
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "targetProperty", void 0);
    __decorate([
        rdfine_1.property.resource()
    ], Impl.prototype, "sourceColumn", void 0);
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "datatype", void 0);
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "language", void 0);
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "defaultValue", void 0);
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "dimensionType", void 0);
    Impl = __decorate([
        (0, rdfine_1.namespace)(namespace_1.cc)
    ], Impl);
    return Impl;
}
exports.LiteralColumnMappingMixin = LiteralColumnMappingMixin;
LiteralColumnMappingMixin.appliesTo = namespace_1.cc.LiteralColumnMapping;
function IdentifierMappingMixin(Resource) {
    let Impl = class Impl extends Resource {
    };
    __decorate([
        rdfine_1.property.resource()
    ], Impl.prototype, "sourceColumn", void 0);
    __decorate([
        rdfine_1.property.resource()
    ], Impl.prototype, "referencedColumn", void 0);
    Impl = __decorate([
        (0, rdfine_1.namespace)(namespace_1.cc)
    ], Impl);
    return Impl;
}
exports.IdentifierMappingMixin = IdentifierMappingMixin;
function ReferenceColumnMappingMixin(Resource) {
    let Impl = class Impl extends Resource {
    };
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "targetProperty", void 0);
    __decorate([
        rdfine_1.property.resource()
    ], Impl.prototype, "referencedTable", void 0);
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.cc.identifierMapping, values: 'array', as: [IdentifierMappingMixin] })
    ], Impl.prototype, "identifierMapping", void 0);
    __decorate([
        (0, rdfine_1.property)()
    ], Impl.prototype, "dimensionType", void 0);
    Impl = __decorate([
        (0, rdfine_1.namespace)(namespace_1.cc)
    ], Impl);
    return Impl;
}
exports.ReferenceColumnMappingMixin = ReferenceColumnMappingMixin;
ReferenceColumnMappingMixin.appliesTo = namespace_1.cc.ReferenceColumnMapping;
const literalFromPointer = (pointer, initializer) => {
    return rdfine_1.default.factory.createEntity(pointer, [LiteralColumnMappingMixin], {
        initializer: {
            ...initializer,
            types: [namespace_1.cc.ColumnMapping, namespace_1.cc.LiteralColumnMapping],
        },
    });
};
exports.literalFromPointer = literalFromPointer;
const referenceFromPointer = (pointer, initializer) => {
    return rdfine_1.default.factory.createEntity(pointer, [ReferenceColumnMappingMixin], {
        initializer: {
            ...initializer,
            types: [namespace_1.cc.ColumnMapping, namespace_1.cc.ReferenceColumnMapping],
        },
    });
};
exports.referenceFromPointer = referenceFromPointer;
exports.createLiteral = (0, initializer_1.initializer)(LiteralColumnMappingMixin, {
    types: [namespace_1.cc.ColumnMapping, namespace_1.cc.LiteralColumnMapping],
});
exports.createReference = (0, initializer_1.initializer)(ReferenceColumnMappingMixin, {
    types: [namespace_1.cc.ColumnMapping, namespace_1.cc.ReferenceColumnMapping],
});
exports.createIdentifierMapping = (0, initializer_1.initializer)(IdentifierMappingMixin, {});
function isLiteralColumnMapping(columnMapping) {
    return columnMapping.types.has(namespace_1.cc.LiteralColumnMapping);
}
exports.isLiteralColumnMapping = isLiteralColumnMapping;
