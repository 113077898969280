import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message-header"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 1,
  class: "message-body"
}
const _hoisted_5 = {
  key: 2,
  class: "message-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(["message", [_ctx.type, _ctx.size]])
  }, [
    (_ctx.$slots.header || _ctx.title)
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          (_ctx.$slots.header)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "header")
              ]))
            : (_ctx.title)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("section", _hoisted_4, _toDisplayString(_ctx.text), 1))
      : (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true)
  ], 2))
}