import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "is-title is-size-6 mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "table is-fullwidth"
}
const _hoisted_3 = { class: "has-text-weight-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_term_display = _resolveComponent("term-display")!
  const _component_cube_preview_value = _resolveComponent("cube-preview-value")!
  const _component_loading_block = _resolveComponent("loading-block")!
  const _component_side_pane = _resolveComponent("side-pane")!

  return (_openBlock(), _createBlock(_component_side_pane, {
    title: "Resource preview",
    onClose: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, [
        _createVNode(_component_term_display, {
          term: _ctx.resourceId,
          base: _ctx.cubeUri,
          class: "tag is-rounded has-text-weight-bold"
        }, null, 8, ["term", "base"])
      ]),
      (_ctx.resource)
        ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, ([predicate, objects], index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", _hoisted_3, [
                  _createVNode(_component_term_display, {
                    term: predicate,
                    base: _ctx.cubeUri
                  }, null, 8, ["term", "base"])
                ]),
                _createElementVNode("td", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(objects, (object, objectIndex) => {
                    return (_openBlock(), _createElementBlock("p", { key: objectIndex }, [
                      _createVNode(_component_cube_preview_value, {
                        value: object,
                        "cube-uri": _ctx.cubeUri,
                        "selected-language": _ctx.selectedLanguage,
                        "show-language": true
                      }, null, 8, ["value", "cube-uri", "selected-language"])
                    ]))
                  }), 128))
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_loading_block, { key: 1 }))
    ]),
    _: 1
  }, 8, ["onClose"]))
}