
import { defineComponent } from 'vue'
import { mapGetters, useStore } from 'vuex'

import { JobCollection } from '@cube-creator/model'

import ExternalTerm from '@/components/ExternalTerm.vue'
import JobForm from '@/components/JobForm.vue'
import JobItem from '@/components/JobItem.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import { usePolling } from '@/use-polling'

export default defineComponent({
  name: 'PublicationView',
  components: { ExternalTerm, LoadingBlock, JobForm, JobItem },

  setup () {
    const store = useStore()
    usePolling(() => store.dispatch('project/fetchJobCollection'))
  },

  computed: {
    ...mapGetters('project', {
      jobs: 'publicationJobs',
    }),

    language (): string[] {
      return this.$store.state.app.language
    },

    jobCollection (): JobCollection | null {
      return this.$store.state.project.jobCollection
    },
  },
})
