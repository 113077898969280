import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "has-text-grey"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hierarchy_tree_level = _resolveComponent("hierarchy-tree-level")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roots, (root) => {
      return (_openBlock(), _createBlock(_component_hierarchy_tree_level, {
        key: root.value,
        root: root,
        "next-level": _ctx.nextLevel,
        "endpoint-url": _ctx.endpointUrl
      }, null, 8, ["root", "next-level", "endpoint-url"]))
    }), 128)),
    (_ctx.roots.length === 0)
      ? (_openBlock(), _createElementBlock("li", _hoisted_1, " Empty "))
      : _createCommentVNode("", true)
  ]))
}