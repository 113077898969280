
import { defineComponent } from 'vue'
import BLoading from '@/components/BLoading.vue'
import NavBar from '@/components/NavBar.vue'
import { APIErrorAuthorization } from './api/errors'
import { Message } from './store/modules/app'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'App',
  components: { BLoading, NavBar },

  mounted (): void {
    this.$store.dispatch('app/loadCommonRDFProperties')

    window.addEventListener('vuexoidc:userLoaded', this.onUserLoaded)
  },

  unmounted (): void {
    window.removeEventListener('vuexoidc:userLoaded', this.onUserLoaded)
  },

  errorCaptured (err: unknown): false | void {
    if (err instanceof APIErrorAuthorization) {
      const link = err.details?.link?.href ?? ''
      this.$router.push({ name: 'NotAuthorized', params: { link } })
      return false
    }
  },

  computed: {
    ...mapState('app', {
      isLoading: 'loading',
      messages: 'messages',
    }),

    release (): string {
      const commit = process.env.VUE_APP_COMMIT?.slice(0, 7) ?? 'dev'
      return `${process.env.VUE_APP_VERSION} (${commit})`
    },
  },

  methods: {
    onUserLoaded (): void {
      // Clear stale state in OIDC store to avoid "Request Header Or Cookie Too Large" error
      this.$store.dispatch('auth/clearStaleState')
    },

    dismissMessage (message: Message): void {
      this.$store.dispatch('app/dismissMessage', message)
    },
  },
})
