
import { GraphPointer } from 'clownface'
import { defineAsyncComponent, defineComponent, PropType } from 'vue'

import { NextInHierarchy } from '@/store/types'

const HierarchyTreeLevel: any = defineAsyncComponent(() => import('./HierarchyTreeLevel.vue'))

export default defineComponent({
  name: 'HiearchyTree',
  components: { HierarchyTreeLevel },
  props: {
    roots: {
      type: Array as PropType<GraphPointer[]>,
      required: true,
    },
    nextLevel: {
      type: Object as PropType<NextInHierarchy>,
      default: null,
    },
    endpointUrl: {
      type: String,
      required: true,
    },
  },
})
