
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import humanizeDuration, { HumanizerOptions } from 'humanize-duration'
import { Project } from '@cube-creator/model'
import { RdfResource } from 'alcaeus'

import LoadingBlock from '../components/LoadingBlock.vue'
import ExternalTerm from '../components/ExternalTerm.vue'

const msInDay = /* hours */ 24 * /* seconds */ 3600 * /* milliseconds */ 1000
const twentyDays = 20 * msInDay
const thirtyDays = 30 * msInDay

export default defineComponent({
  name: 'CubeProjectsItem',
  components: { LoadingBlock, ExternalTerm },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    }
  },

  data () {
    return {
      detailsShown: false,
    }
  },

  computed: {
    ...mapGetters({
      getDetails: 'projects/getProjectDetails',
    }),

    details (): RdfResource | null {
      return this.getDetails(this.project)
    },
  },

  methods: {
    toggleDetails (): void {
      if (!this.detailsShown) {
        this.$store.dispatch('projects/fetchProjectDetails', this.project)
      }

      this.detailsShown = !this.detailsShown
    },

    timeUntilNextUpdate (nextUpdate: Date) {
      const duration = nextUpdate.valueOf() - new Date().valueOf()

      let sign = ''
      if (duration < 0) {
        sign = '- '
      }

      const options: HumanizerOptions = { largest: 1, round: true }
      if (duration < thirtyDays) {
        options.units = ['d']
      }

      return sign + humanizeDuration(duration, options)
    },

    nextUpdateWarning (nextUpdate: Date) {
      const duration = nextUpdate.valueOf() - new Date().valueOf()
      if (duration < twentyDays) {
        return 'has-background-warning'
      }

      return ''
    }
  },
})
